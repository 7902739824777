/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ThemeProvider } from "@material-ui/styles"
import { createMuiTheme } from "@material-ui/core/styles"
import { graphql, StaticQuery } from "gatsby"
// Components
import Toolbar from "../toolbar"
// Assets
import logo from "../../assets/svg/Logo_Transsolar_SoftwareEngineering.svg"
// Actions
import { setCorrespondingLangSlug, toggleLanguage } from "../../state/actions"
import Footer from "../footer"
import { DialogProvider } from "../../hooks/useDialog"
import { withStyles } from "@material-ui/core"


const Layout = ({ children, lang }) => {
  const [isTop, setIsTop] = useState(true)
  // const menuItems = menuTopNavigationItems()

  useEffect(() => {
    const handleScroll = () => {
      const limit = (window.scrollY || document.documentElement.scrollTop) < 75
      if (limit !== isTop) {
        setIsTop(limit)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const scrolled = isTop ? "" : "scrolled"

  const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    "@global": {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      ".MuiButton-root": {
        fontSize: "1rem",
        boxShadow: "none",
        borderRadius: "4px",
      },
      ".MuiFab-root": {
        backgroundColor: "transparent",
      },
      ".MuiFab-root > .MuiFab-label": {
        fontSize: "1rem",
      },
      ".MuiFab-root:hover": {
        backgroundColor: "#d5d5d5",
      },
      ".MuiFab-root:active": {
        boxShadow: "none",
      },
      ".MuiTooltip-tooltip": {
        fontSize: "0.875rem",
      },
      ".MuiTooltip-popper": {
        position: "fixed !important",
        top: "initial !important",
        left: "initial !important",
        right: 85,
        bottom: 44,
        transform: "none !important",
      },
      ".MuiListItemText-root": {
        flex: "1 0 auto",
      },
      ".MuiListItemIcon-root": {
        minWidth: 0,
      },
      ".MuiTableRow-hover": {
        cursor: "pointer"
      },
      ".MuiButton-contained > .MuiButton-label, .MuiButton-outlined > .MuiButton-label": {
        fontWeight: 700,
      },
      ".MuiButton-contained": {
        fontSize: "1rem",
        boxShadow: "none",
        border: "3px solid #FFCA28",
      },
      ".MuiButton-contained:hover": {
        backgroundColor: "transparent",
        color: "#FFCA28",
        boxShadow: "none",
      },
      ".MuiButton-outlined": {
        fontSize: "1rem",
        border: "3px solid #FFCA28",
      },
      ".MuiButton-outlined:hover": {
        backgroundColor: "#FFCA28",
        color: "white",
        boxShadow: "none",
        border: "3px solid #FFCA28",
      },
    },
  })(() => null)

  let theme = createMuiTheme({
    palette: {
      type: "light",
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#FFCA28",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff",
      },
      secondary: {
        main: "#FFAC33",
        // dark: will be calculated from palette.secondary.main,
      },
      // text: {
      //   primary: "rgba(38, 50, 56, 1.0)",
      //   secondary: "rgba(38, 50, 56, 0.87)",
      //   disabled: "rgba(38, 50, 56, 0.38)",
      // },

    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 360,
        md: 480,
        lg: 768,
        xl: 1200,
      },
    },
    shape: {
      borderRadius: 10,
    },
    overrides: {
      MuiPaper: {
        elevation20: {
          boxShadow: "0 0 36px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  })

  return (
    <StaticQuery
      query={menuTopNavigationItems}
      render={data => {
        const menuItems = {
          de_DE: data.wpgraphql.menuDE.nodes[0].menuItems.nodes,
          en_US: data.wpgraphql.menuEN.nodes[0].menuItems.nodes,
        }

        const footerSettings = data.wpgraphql.footerSettings.footerSettings

        const translatedMenuItems = menuItems[lang]

        return (
          <ThemeProvider theme={theme}>
            <GlobalCss/>
            <DialogProvider>
              <div className={classNames("Site", scrolled)}>

                <Toolbar menuItems={translatedMenuItems} logo={logo}/>

                <main className="Main">{children}</main>
                <Footer logo={logo} footerSettings={footerSettings}/>

              </div>
            </DialogProvider>
          </ThemeProvider>
        )
      }}/>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  }
}

const mapDispatchToProps = {
  toggleLanguage,
  setCorrespondingLangSlug,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)


const menuTopNavigationItems = graphql`
    query MenuTopNavigationItems {
        wpgraphql {
            menuDE: menus(where: {location: MENU_1}) {
                nodes {
                    menuItems {
                        nodes {
                            url
                            relativeUrl
                            target
                            label
                            childItems {
                                nodes {
                                    url
                                    target
                                    label
                                    id
                                    menuItemId
                                    relativeUrl
                                }
                            }
                            id
                            menuItemId
                        }
                    }
                }
            }
            menuEN: menus(where: {location: MENU_1___EN}) {
                nodes {
                    menuItems {
                        nodes {
                            url
                            relativeUrl
                            target
                            label
                            childItems {
                                nodes {
                                    url
                                    target
                                    label
                                    id
                                    menuItemId
                                    relativeUrl
                                }
                            }
                            id
                            menuItemId
                        }
                    }
                }
            }
            footerSettings {
                footerSettings {
                    germanFooterSettings {
                        rowOne {
                            title
                            links {
                                linkItem {
                                    url
                                    title
                                    target
                                    relativeUrl
                                }
                            }
                        }
                        rowTwo {
                            title
                            links {
                                linkItem {
                                    relativeUrl
                                    target
                                    title
                                    url
                                }
                            }
                        }
                        rowThree {
                            title
                            content
                        }
                    }
                    englishFooterSettings {
                        rowOne {
                            title
                            links {
                                linkItem {
                                    url
                                    title
                                    target
                                    relativeUrl
                                }
                            }
                        }
                        rowTwo {
                            title
                            links {
                                linkItem {
                                    relativeUrl
                                    target
                                    title
                                    url
                                }
                            }
                        }
                        rowThree {
                            title
                            content
                        }
                    }
                }
            }
        }
    }
`
