import React, { createContext, useContext } from "react"
import DialogContent from "@material-ui/core/DialogContent"

const DialogContext = createContext(null)

export const DialogProvider = ({ children }) => (
  <DialogContext.Provider value={useProvideDialog()}>
    {children}
  </DialogContext.Provider>
)

export const useDialog = () => useContext(DialogContext)


const useProvideDialog = () => {
  /* ------ Dialog ------ */
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState("")
  const [dialogContent, setDialogContent] = React.useState("")

  const handleDialogOpen = (title, content, innerHtml = true) => {
    setDialogTitle(title)
    if (innerHtml) {
      setDialogContent(<DialogContent className={"u-applyListStyle"} dividers={true} dangerouslySetInnerHTML={{ __html: content }}/>)
    } else {
      setDialogContent(<DialogContent className={"u-applyListStyle"} dividers={true}>{content}</DialogContent>)
    }
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return {
    handleDialogOpen,
    handleDialogClose,
    dialogOpen,
    dialogTitle,
    dialogContent
  }
}
