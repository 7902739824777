import PropTypes from "prop-types"
import React from "react"

import "./section-inner.scss"
import classNames from "classnames"

const SectionInner = ({ className, children, tag: Tag, style }) => {

  return (
    <Tag className={classNames("SectionInner", className)} style={style}>
      {children}
    </Tag>
  )
}

SectionInner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tag: PropTypes.string,
}

SectionInner.defaultProps = {
  tag: `div`,
  children: [],
}

export default SectionInner
