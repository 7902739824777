import React from "react"
import "./lang-switcher.scss"
import Fab from "@material-ui/core/Fab"
import { setToLanguage, toggleLanguage } from "../../state/actions"
import { connect } from "react-redux"
import { navigate } from "../../../.cache/gatsby-browser-entry"


const LangSwitcher = ({ lang, correspondingLangSlug, setToLanguage }) => {

  const handleClick = (e, langSlug, desiredLanguage, activeLanguage) => {
    if (desiredLanguage !== activeLanguage) {
      // setToLanguage(desiredLanguage)
      navigate(langSlug)
    }
  }

  return (
    <div className={"LangSwitcher " + lang}>
      <Fab className="LangSwitcher-de u-noShadow"
                    onClick={(e) => handleClick(e, correspondingLangSlug, "de_DE", lang)}
                    href={null}
                    size="small"
                    aria-label="DE"
      >DE</Fab>
      {"|"}
      <Fab onClick={(e) => handleClick(e, correspondingLangSlug, "en_US", lang)}
                    href={null}
                    size="small"
                    aria-label="EN"
                    className="LangSwitcher-en u-noShadow"
      >EN</Fab>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    correspondingLangSlug: state.correspondingLangSlug,
  }
}

const mapDispatchToProps = {
  toggleLanguage,
  setToLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(LangSwitcher)
