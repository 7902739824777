import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import Grid from "@material-ui/core/Grid"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { withTranslation } from "react-i18next"

// import FacebookIcon from "../../assets/svg/icons/facebook.svg"
// import LinkedInIcon from "../../assets/svg/icons/linkedin.svg"

import "./footer.scss"
import Section from "../section"
import SectionInner from "../section-inner"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import Link from "../link"

const Footer = ({ t, className, style, logo, lang, correspondingLangSlug, footerSettings }) => {
  const Logo = logo

  const inputLabel = React.useRef(null)

  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  function handleChange(e, langSlug, activeLanguage) {
    if (e.target.value !== activeLanguage) {
      // setToLanguage(desiredLanguage)
      navigate(langSlug)
    }
  }

  const translatedFooterSettings = lang === "de_DE" ? footerSettings.germanFooterSettings : footerSettings.englishFooterSettings
  return (
    <Section tag={"footer"} className={classNames("Footer", "c-dark-primary", className)} style={style}>
      <SectionInner className="Footer-inner">
        <Grid container className="Footer-grid" spacing={5}>
          <Grid className="Footer-gridItem" item xs={12} xl={3}>
            <div className="Footer-logo">
              <Link to="/">
                <Logo className="Footer-logoIcon c-dark-primary"/>
              </Link>
            </div>

            {/*<p className="c-dark-secondary">{t("Klima- und SoftwareEngeneering")}</p>*/}

            {/*<div className="Footer-socials">*/}
            {/*  /!*<FacebookIcon/>*!/*/}
            {/*  /!*<LinkedInIcon/>*!/*/}
            {/*</div>*/}

          </Grid>
          <Grid className="Footer-gridItem" item xs={12} lg={4} xl={3}>
            <h3 className="t-overline">{translatedFooterSettings.rowOne.title}</h3>
            <ul className="Footer-listItem t-body3 c-dark-secondary">
              {
                translatedFooterSettings &&
                translatedFooterSettings.rowOne.links.map((link, index) => {
                  if (!link.linkItem) return null
                  return <li key={index}>
                    <Link to={link.linkItem.relativeUrl} partiallyActive={true} title={link.linkItem.title}>
                      {link.linkItem.title}
                    </Link>
                  </li>
                })
              }
            </ul>
          </Grid>
          <Grid className="Footer-gridItem" item xs={12} lg={4} xl={3}>
            <h3 className="t-overline">{translatedFooterSettings.rowTwo.title}</h3>
            <ul className="Footer-listItem t-body3 c-dark-secondary">
              {
                translatedFooterSettings &&
                translatedFooterSettings.rowTwo.links.map((link, index) => {
                  return <li key={index}>
                    <Link to={link.linkItem.relativeUrl} partiallyActive={true} activeClassName={"active"}
                          title={link.linkItem.title}>
                      {link.linkItem.title}
                    </Link>
                  </li>
                })
              }
            </ul>
          </Grid>
          <Grid className="Footer-gridItem" item xs={12} lg={4} xl={3}>
            <h3 className="t-overline">{translatedFooterSettings.rowThree.title}</h3>
            <ul className="Footer-listItem t-body3 c-dark-secondary"
                dangerouslySetInnerHTML={translatedFooterSettings && {__html: translatedFooterSettings.rowThree.content}}
            />
          </Grid>
        </Grid>
        <div className="Footer-bottomInfo">
          <span className="c-dark-secondary">{t("© Transsolar Energietechnik GmbH")}</span>
          <FormControl variant="outlined" className="Footer-langSelect">
            <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
              {t("Sprache")}
            </InputLabel>
            <Select
              value={lang}
              onChange={(e) => handleChange(e, correspondingLangSlug, lang)}
              input={<OutlinedInput labelWidth={labelWidth} name="age" id="outlined-age-simple"/>}
            >
              <MenuItem value={"de_DE"}>Deutsch</MenuItem>
              <MenuItem value={"en_US"}>English</MenuItem>
            </Select>
          </FormControl>
        </div>
      </SectionInner>
    </Section>
  )
}

Footer.propTypes = {
  logo: PropTypes.func,
}


const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    correspondingLangSlug: state.correspondingLangSlug,
  }
}


export default withTranslation()(connect(mapStateToProps)(Footer))
