import Link from "../link"
import PropTypes from "prop-types"
import React from "react"

import "./navigation.scss"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ArrowDropUp from "@material-ui/icons/ArrowDropUp"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import Home from "@material-ui/icons/HomeRounded"
import { connect } from "react-redux"
import Button from "@material-ui/core/Button"
import { Paper } from "@material-ui/core"
import ListItemText from "@material-ui/core/ListItemText"

const Navigation = ({ menuItems, lang }) => {

  const NavigationItem = ({ page, index }) => {
    let render = ""
    const [open, setOpen] = React.useState(false)

    const handleNavigationDropdownClick = (e, choice) => {
      e.preventDefault()
      setOpen(choice)
    }

    const showArrow = () => {
      return open ? <ArrowDropUp className="Navigation-icon c-accent-dark"/> :
        <ArrowDropDown className="Navigation-icon c-accent-dark"/>
    }

    if (page.childItems && page.childItems.nodes.length > 0) {
      render = (
        <div key={index} className={"Navigation-linkWrapper"}>
          <Button variant={"text"} size={"small"}
                  className="Navigation-link Navigation-link--withMargin Navigation-link--noRightMargin u-cursor-pointer"
                  key={page.menuItemId}
                  onClick={(e) => handleNavigationDropdownClick(e, !open)}
                  onMouseEnter={(e) => handleNavigationDropdownClick(e, true)}
                  onMouseLeave={(e) => handleNavigationDropdownClick(e, false)}
          >
            {page.label}
            {showArrow()}
          </Button>

          <Collapse className={"Navigation-collapse"} in={open} timeout="auto"
                    unmountOnExit onMouseEnter={(e) => handleNavigationDropdownClick(e, true)} onMouseLeave={(e) => handleNavigationDropdownClick(e, false)}>
            <List component={Paper} className={"u-border"} disablePadding elevation={1}>
              {page.childItems.nodes.map((subPage) => {
                return (
                  <ListItem className="Navigation-link Navigation-dropDownLink u-border" button key={subPage.menuItemId} component={Link}
                            style={{ backgroundColor: "white" }}
                            to={subPage.relativeUrl} activeClassName="active"
                            partiallyActive={true}>
                    <ListItemText primary={subPage.label}/>
                  </ListItem>
                  // <ListItem key={subPage.menuItemId} className="Navigation-link"
                  //           to={subPage.relativeUrl} activeClassName="active"
                  //           button
                  //           partiallyActive={true}
                  //           >
                  //
                  //   {/*{*/}
                  //   {/*  isAbsoluteUrl(subPage.relativeUrl) &&*/}
                  //   {/*  <ListItemIcon><LaunchIcon/></ListItemIcon>*/}
                  //   {/*}*/}
                  //   {/*{subPage.label}*/}
                  //   <ListItemText primary={<Typography>{subPage.label}</Typography>} />
                  //   {
                  //     isAbsoluteUrl(subPage.relativeUrl) && <ListItemIcon><LaunchIcon /></ListItemIcon>
                  //   }
                  //
                  // </ListItem>
                )
              })}
            </List>
          </Collapse>
        </div>)
      return render
    }
    render = <Button component={Link} size={"small"} className="Navigation-link Navigation-link--withMargin"
                     activeClassName="active" partiallyActive={true} key={page.menuItemId}
                     to={page.relativeUrl}>{page.label}</Button>
    return render
  }

  return (
    <nav className="Navigation">
      <Link className="Navigation-link Navigation-link--isHome" activeClassName="active"
            to={lang === "de_DE" ? "/" : "/en"}>
        <Home className="Navigation-icon"/>
      </Link>

      {menuItems
        .map((page, index) => {
          return <NavigationItem key={index} page={page}/>
        })}
    </nav>
  )
}

Navigation.propTypes = {
  menuItems: PropTypes.array,
}

Navigation.defaultProps = {
  menuItems: [],
}


const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  }
}

export default connect(mapStateToProps)(Navigation)
