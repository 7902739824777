import * as actionType from "./actionTypes"

/*
 * action creators
 */


export const toggleLanguage = () => ({
  type: actionType.TOGGLE_LANGUAGE,
})

export const setToLanguage = (lang) => ({
  type: actionType.SET_TO_LANGUAGE,
  payload: lang
})

export const setCorrespondingLangSlug = (slug) => ({
  type: actionType.SET_CORRESPONDING_LANG_SLUG,
  payload: slug
})

export const setShippingAddress = (address) => ({
  type: actionType.SET_SHIPPING_ADDRESS,
  payload: address
})

export const setBillingAddress = (address) => ({
  type: actionType.SET_BILLING_ADDRESS,
  payload: address
})

export const setBillingIsSame = (isSame) => ({
  type: actionType.SET_BILLING_IS_SAME,
  payload: isSame
})

export const setLicenseType = (type) => ({
  type: actionType.SET_LICENSE_TYPE,
  payload: type
})

export const setUserId = (id) => ({
  type: actionType.SET_USER_ID,
  payload: id
})

export const setUst = (number) => ({
  type: actionType.SET_UST,
  payload: number
})

export const setOrderType = (type) => ({
  type: actionType.SET_ORDER_TYPE,
  payload: type
})

export const setLicenseAmount = (amount) => ({
  type: actionType.SET_LICENSE_AMOUNT,
  payload: amount
})

export const setPriceCalculated = (price) => ({
  type: actionType.SET_PRICE_CALCULATED,
  payload: price
})

export const setWantsNewsletter = (choice) => ({
  type: actionType.SET_WANTS_NEWSLETTER,
  payload: choice
})

export const setIsUpdate = (type) => ({
  type: actionType.SET_IS_UPDATE,
  payload: type
})

export const setIsUpdateAddons = (type) => ({
  type: actionType.SET_IS_UPDATE_ADDONS,
  payload: type
})

export const setIsEducational = (choice) => ({
  type: actionType.SET_IS_EDUCATIONAL,
  payload: choice
})

export const setIsEducationalAddons = (choice) => ({
  type: actionType.SET_IS_EDUCATIONAL_ADDONS,
  payload: choice
})

export const setSupportInfo = (type) => ({
  type: actionType.SET_SUPPORT_INFO,
  payload: type
})

export const setSupportEmails = (type) => ({
  type: actionType.SET_SUPPORT_EMAILS,
  payload: type
})

export const setAdvancedSupport = (type) => ({
  type: actionType.SET_ADVANCED_SUPPORT,
  payload: type
})

export const resetOrder = () => ({
  type: actionType.RESET_ORDER,
})

// Addons

export const setAddonOrders = (addons) => ({
  type: actionType.SET_ADDON_ORDERS,
  payload: addons
})

export const updateAddonCount = (id, count) => ({
  type: actionType.UPDATE_ADDON_COUNT,
  payload: {
    id,
    count
  }
})

export const setShowOrderFab = (flag) => ({
  type: actionType.SET_SHOW_ORDER_FAB,
  payload: flag // 0 = not set, 1 = just set, 2 = already been set once
})


