import React from "react"
import { Link as GatsbyLink } from "gatsby"
import isAbsoluteUrl from "is-absolute-url"

const Link = React.forwardRef(({ to, partiallyActive, activeClassName, absolute, children, ...props }, ref) =>
  isAbsoluteUrl(to) || absolute ? (
    <a href={to} {...props} ref={ref} target="_blank" rel="noopener noreferrer">{children}</a>
  ) : (
    <GatsbyLink to={to} {...props} ref={ref} partiallyActive={partiallyActive} activeClassName={activeClassName}>{children}</GatsbyLink>
  ));

export default Link;
