import PropTypes from "prop-types"
import React from "react"

import "./drawer.scss"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import Fab from "@material-ui/core/Fab"
import colors from "../../assets/sass/common/_colors.scss"
import CloseIcon from "../../assets/svg/icons/close.svg"
import LangSwitcher from "../lang-switcher"
import { ListItemIcon } from "@material-ui/core"
import Link from "../link"
// import CartIcon from "../../assets/svg/icons/shopping-cart-solid.svg"
// import SearchIcon from "../../assets/svg/icons/search.svg"
import HomeIcon from "@material-ui/icons/HomeRounded"

import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"


const Drawer = ({ t, lang, menuItems, toggleFunction }) => {

  const MenuItems = ({page}) => {


    const [open, setOpen] = React.useState(false);

    function handleClick() {
      setOpen(!open);
    }

    if (page.childItems === null || page.childItems.nodes.length === 0) {
      return (
        <ListItem button key={page.menuItemId} component={Link} to={page.relativeUrl} activeClassName="active"
                  partiallyActive={true}>
          <ListItemText primary={page.label}/>
        </ListItem>
      )
    } else {
      return (
        <div key={page.menuItemId}>
          <ListItem button onClick={handleClick}>
            <ListItemText primary={page.label}/>
            {open ? <ExpandLess /> : <ExpandMore /> }
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{paddingLeft: "16px"}}>
              {page.childItems.nodes.map((subPage) => (
                <ListItem button key={subPage.menuItemId} component={Link} to={subPage.relativeUrl} activeClassName="active"
                          partiallyActive={true}>
                  <ListItemText primary={subPage.label}/>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      )
    }
  }

  return (
    <div
      role="presentation"
      // onClick={toggleFunction(side, false)}
      // onKeyDown={toggleFunction(side, false)}
      className="Drawer"
    >
      <Fab size="medium" aria-label="Close Menu" className="CloseButton u-noShadow"
           onClick={toggleFunction(false)}
           style={{
             color: colors.colorTextMain,
             backgroundColor: "transparent",
           }}>
        <CloseIcon/>
      </Fab>
      <LangSwitcher style={{ display: "inline-block" }}/>
      <Divider/>

      <List style={{ width: "250px" }}>
        {menuItems.map((page, index) => <MenuItems key={index} page={page} />)}
      </List>
      <Divider/>
      <List>

        {/*<ListItem button>*/}
        {/*  <ListItemIcon>*/}
        {/*    <SearchIcon/>*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary={t("Suche")}/>*/}
        {/*</ListItem>*/}

        {/*<ListItem button>*/}
        {/*  <ListItemIcon>*/}
        {/*    <CartIcon/>*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="Warenkorb"/>*/}
        {/*</ListItem>*/}

        <ListItem button component={Link} to={lang === "de_DE" ? '/' : '/en'}>
          <ListItemIcon>
            <HomeIcon/>
          </ListItemIcon>
          <ListItemText className={"u-leftMarginSm"} primary={t("Startseite")} />
        </ListItem>

      </List>
    </div>
  )
}


Drawer.propTypes = {
  side: PropTypes.string,
  menuItems: PropTypes.array,
  toggleFunction: PropTypes.func,
}

Drawer.defaultProps = {
  side: `left`,
  menuItems: [],
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  }
}

export default withTranslation()(connect(mapStateToProps)(Drawer))
