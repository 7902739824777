import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Fab from "@material-ui/core/Fab"
// import SearchIcon from "../../assets/svg/icons/search.svg"
import MenuIcon from "../../assets/svg/icons/menu.svg"

import "./toolbar.scss"
import Navigation from "../navigation"
import Drawer from "../drawer"
import LangSwitcher from "../lang-switcher"
import Section from "../section"
import SectionInner from "../section-inner/section-inner"
import { connect } from "react-redux"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useDialog } from "../../hooks/useDialog"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import { Close as CloseIcon } from "@material-ui/icons"


const Toolbar = ({ menuItems, logo, lang }) => {

  //TODO: find a proper place for this, probably better in a global state
  const [showDrawer, setShowDrawer] = React.useState(false)

  const toggleDrawer = (open) => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    setShowDrawer(open)
  }

  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const Logo = logo
  const { dialogOpen, dialogTitle, dialogContent, handleDialogClose } = useDialog()

  return (
    <Section className="Toolbar">
      <SectionInner tag="header" className="Toolbar-inner">
        <SwipeableDrawer
          open={showDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <Drawer side="left" menuItems={menuItems} toggleFunction={toggleDrawer}/>
        </SwipeableDrawer>

        <Fab href={null} size="small" aria-label="Menu" className="BurgerButton u-noShadow"
             onClick={toggleDrawer(true)}
             style={{ backgroundColor: "transparent", marginBottom: "-2px" }}>
          <MenuIcon/>
        </Fab>
        <div className="Logo">
          <Link to={lang === "de_DE" ? "/" : "/en"}>
            {/*<GatsbyImage className="Logo-icon" fluid={logo.childImageSharp.fluid} />*/}
            <Logo className="Logo-icon"/>
          </Link>
        </div>

        <Navigation menuItems={menuItems}/>

        <LangSwitcher/>

        {/*<Fab href={null} size="small" aria-label="Menu" className="ShoppingCartButton u-noShadow">*/}
        {/*  <CartIcon style={{ marginRight: "2px" }}/>*/}
        {/*</Fab>*/}


        {/*<Fab href={null} size="small" aria-label="Search" className="SearchButton u-noShadow">*/}
        {/*  <SearchIcon/>*/}
        {/*</Fab>*/}

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth={true}
          maxWidth={"lg"}
          scroll={"paper"}
        >
          <DialogTitle id="scroll-dialog-title" className={"Dialog-title"} disableTypography>
            <Typography variant="h6">
              {dialogTitle}
            </Typography>

            {dialogOpen ? (
              <IconButton aria-label="close" className={"Dialog-closeButton"} onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            ) : null}

          </DialogTitle>
          {dialogContent}

        </Dialog>
      </SectionInner>
    </Section>
  )

}

Toolbar.propTypes = {
  menuItems: PropTypes.array,
  // logo: PropTypes.func,
}

Toolbar.defaultProps = {
  menuItems: [],
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  }
}

export default connect(mapStateToProps)(Toolbar)
