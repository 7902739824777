import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import "./section.scss"

const Section = ({ className, tag, children, style, ...props }) => {
  const Tag = tag
  return (
    <Tag {...props} className={classNames("Section", className)} style={style} >
      {children}
    </Tag>
  )
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

Section.defaultProps = {
  tag: "section"
}

export default Section
